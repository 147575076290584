<template>
  <div class="toz-spinner overflow-hidden">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 120 120"
    >
      <circle
        cx="60"
        cy="60"
        r="54"
        fill="none"
        stroke="currentColor"
        stroke-width="12"
        stroke-dasharray="339.292"
        stroke-dashoffset="190"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ToznyLoader',
}
</script>

<style lang="scss">
.toz-spinner {
  display: inline-block;
  position: relative;
  transform: rotate(180deg);
}

.toz-spinner > svg {
  stroke-linecap: round;
  animation: load 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  left: 0;
}

.toz-spinner > svg + svg {
  animation-delay: 250ms;
}

@keyframes load {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
  95% {
    transform: rotate(360deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotate(360deg) translate3d(0, 0, 0);
  }
}
</style>
