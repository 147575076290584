/**
 * State associated with access requests.
 */

import { rootIdentity } from '../../utils/utils'

const statuses = {
  INITIALIZING: 'initializing',
  INITIALIZED: 'initialized',
  LOADING: 'loading',
}

const actionNames = {
  CREATE: 'create',
  APPROVE: 'approve',
  DENY: 'deny',
}

const accessRequestStates = {
  OPEN: 'open',
  APPROVED: 'approved',
}

/** initial session state */
const state = {
  status: statuses.INITIALIZING,
  errorMessage: '',
  accessRequestsUserCanApprove: [],
  accessRequestsUserCreated: [],
  detailedAccessRequest: null,
  clientId: '',
  groups: [],
  isOpen: (request) => request.state === accessRequestStates.OPEN,
  actionNames,
}

/** cache-able getters */
const getters = {
  accessRequestsForUser: (state) =>
    state.accessRequestsUserCreated.filter(state.isOpen),
  accessRequestsForApproval: (state, getters) =>
    state.accessRequestsUserCanApprove.filter(
      (req) => state.isOpen(req) && !getters.wasActedOnByUser(req)
    ),
  accessRequestsHistory: (state, getters) =>
    [
      // all non-open requests i created
      ...state.accessRequestsUserCreated.filter((r) => !state.isOpen(r)),
      // all approvable-by-me requests i actually interacted with
      ...state.accessRequestsUserCanApprove.filter((r) =>
        getters.wasActedOnByUser(r)
      ),
      // sorted in reverse chronological order. leverages string dates: reverse alphabetical!
    ].sort((a, b) => b.createdAt.localeCompare(a.createdAt)),

  initialized: (state) => state.status === statuses.INITIALIZED,

  wasActedOnByUser: (state) => (request) =>
    request.actions.some((a) => a.user.toznyId === state.clientId),
  actionableByUser: (_, getters) => (request) =>
    getters.accessRequestsForApproval.some((r) => r.id === request.id),

  countApprovals: () => (request) =>
    request.actions.reduce(
      (count, { action }) =>
        action === actionNames.APPROVE ? count + 1 : count,
      0
    ),
}

/** synchronous mutations of state */
const mutations = {
  SET_ERROR(state, error) {
    state.errorMessage = error
  },
  SET_STATUS(state, newStatus) {
    state.status = newStatus
  },
  SET_CLIENT_ID(state, clientId) {
    state.clientId = clientId
  },
  SET_APPROVABLE_ACCESS_REQUESTS(state, accessRequests) {
    state.accessRequestsUserCanApprove = accessRequests
  },
  SET_CREATED_ACCESS_REQUESTS(state, accessRequests) {
    state.accessRequestsUserCreated = accessRequests
  },
  ADD_ACCESS_REQUEST(state, newAccessRequest) {
    state.accessRequestsUserCreated = [
      ...state.accessRequestsUserCreated,
      newAccessRequest,
    ]
  },
  SET_GROUPS(state, groups) {
    state.groups = groups
  },
  UPDATE_ACCESS_REQUEST(state, updatedAccessRequest) {
    // finds access request by id in the correct state array and replaces it with the updated thing
    if (updatedAccessRequest.requestor.toznyId === state.clientId) {
      state.accessRequestsUserCreated = state.accessRequestsUserCreated.map(
        (ar) => (ar.id === updatedAccessRequest.id ? updatedAccessRequest : ar)
      )
    } else {
      state.accessRequestsUserCanApprove =
        state.accessRequestsUserCanApprove.map((ar) =>
          ar.id === updatedAccessRequest.id ? updatedAccessRequest : ar
        )
    }
  },
  SET_DETAILED_ACCESS_REQUEST(state, accessRequest) {
    state.detailedAccessRequest = accessRequest
  },
  CLEAR(state) {
    state.accessRequestsUserCanApprove = []
    state.accessRequestsUserCreated = []
    state.clientId = ''
    state.detailedAccessRequest = null
    state.groups = []
    state.errorMessage = ''
  },
}

/**
 * Callable state transitions that facilitate async actions and state transitions
 */
const actions = {
  setLoading({ commit }) {
    commit('SET_STATUS', statuses.LOADING)
  },
  async refreshAccessRequests({ commit, dispatch, rootState }) {
    await dispatch('setLoading')
    const identity = rootIdentity(rootState)

    const groups = await identity.availableAccessRequestGroups(
      rootState.realmName
    )
    commit('SET_GROUPS', groups)

    const clientId = identity.storage.config.clientId
    commit('SET_CLIENT_ID', clientId)

    // TODO: error handling
    const approvable = await identity.searchAccessRequests()
    const created = await identity.searchAccessRequests({
      requestorIds: [identity.storage.config.clientId],
    })

    commit('SET_APPROVABLE_ACCESS_REQUESTS', approvable.accessRequests)
    commit('SET_CREATED_ACCESS_REQUESTS', created.accessRequests)
    commit('SET_STATUS', statuses.INITIALIZED)
  },
  /**
   * approveAccessRequest will approve the request with an optional comment.
   * @param {number} accessRequestId id of the access request to approve
   * @param {string} [comment] additional comment from approver
   */
  async approveAccessRequest(
    { commit, rootState, state },
    accessRequestId,
    comment = ''
  ) {
    const identity = rootIdentity(rootState)
    const response = await identity.approveAccessRequests(rootState.realmName, [
      { accessRequestId, comment },
    ])
    commit('UPDATE_ACCESS_REQUEST', response[0])
    if (
      state.detailedAccessRequest &&
      state.detailedAccessRequest.id === accessRequestId
    ) {
      commit('SET_DETAILED_ACCESS_REQUEST', response[0])
    }
  },
  /**
   * createAccessRequest sends the upsert request to the API
   * data contains:
   *
   * @param {Object} data information about the request for access
   * @param {string} data.groupId id of group they want access to. (someday maybe multiple!)
   * @param {string} data.reason why they want access
   * @param {number} data.accessDurationSeconds how many seconds they're requesting access for
   */
  async createAccessRequest({ commit, rootState }, data) {
    commit('SET_ERROR', '')
    const identity = rootIdentity(rootState)
    try {
      const response = await identity.createAccessRequest(
        rootState.realmName,
        [{ id: data.groupId }],
        data.reason,
        data.accessDurationSeconds
      )
      commit('ADD_ACCESS_REQUEST', response)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('something went wrong', e)
      commit('SET_ERROR', e.message)
    }
  },
  async getAccessRequest(
    { commit, dispatch, getters, state },
    accessRequestId
  ) {
    commit('SET_DETAILED_ACCESS_REQUEST', null)
    if (!getters.initialized) {
      // TODO: maybe update to just describeAccessRequest to minimize network traffic
      await dispatch('refreshAccessRequests')
    }
    // attempt to find in requests they can approve
    const approvable = state.accessRequestsUserCanApprove.find(
      ({ id }) => id === accessRequestId
    )
    if (approvable) {
      commit('SET_DETAILED_ACCESS_REQUEST', approvable)
      return approvable
    }
    // attempt to find in requests they created. otherwise return null
    const created = state.accessRequestsUserCreated.find(
      ({ id }) => id === accessRequestId
    )
    commit('SET_DETAILED_ACCESS_REQUEST', created || null)
    return created || null
  },
  /**
   * denyAccessRequest will deny the request with an optional comment.
   * @param {number} accessRequestId id of the access request to deny
   * @param {string} [comment] additional comment from approver
   */
  async denyAccessRequest(
    { commit, rootState, state },
    accessRequestId,
    comment = ''
  ) {
    const identity = rootIdentity(rootState)
    const response = await identity.denyAccessRequests(rootState.realmName, [
      { accessRequestId, comment },
    ])
    commit('UPDATE_ACCESS_REQUEST', response[0])
    if (
      state.detailedAccessRequest &&
      state.detailedAccessRequest.id === accessRequestId
    ) {
      commit('SET_DETAILED_ACCESS_REQUEST', response[0])
    }
  },
  async reset({ commit }) {
    commit('SET_STATUS', statuses.INITIALIZING)
    commit('CLEAR')
  },
}

export default {
  // namespace this modules actions, mutations, and getters under 'accessRequests' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
