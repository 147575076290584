import {
  clearCache,
  identityCacheKey,
  isUnauthorized,
  loadCache,
  setCache,
} from '@/utils/utils'
import Tozny from '@toznysecure/sdk/browser/sodium'
import Vue from 'vue'
import Vuex from 'vuex'
import tozny from '../api/tozny'
import accessRequests from './modules/accessRequests'
import applications from './modules/applications'
import mfa from './modules/mfa'
import nav from './modules/nav'
import secrets from './modules/secrets'
import sessions from './modules/sessions'

Vue.use(Vuex)
Vue.config.devtools = true
/* Cache for progressive action promise */
let continuation = null

/* Set up modules */
const modules = {
  accessRequests,
  applications,
  mfa,
  nav,
  secrets,
  sessions,
}

/** Network address of the Tozny API to use for making requests */
const TOZNY_API_HOST = global.API_URL
const TOZID_HOSTNAME = global.TOZID_HOSTNAME
const state = {
  globalError: '',
  loginError: '',
  loggingIn: false,
  loginAction: '',
  loginFields: {},
  loginContext: {},
  realmName: '',
  identity: false,
  mfaOptions: [],
  realmVerified: false,
  realmForgotPasswordLink: '',
  realmForgotPasswordText: '',
  realmOIDCLoginRedirectUrl: '',
  realmIdPsExist: false,
  realmExternalIdps: [],
  identityServiceProviderBaseURL: '',
  externalIDPLoginPath: '',
  externalIDPLoginCookies: {},
  // potentialPreferredExternalIDP: '',
  preferredExternalIDP: '',
  loginType: '',
  idpAuthInfo: {},
}
const getters = {
  realm(state) {
    if (state.realmName === '') {
      return null
    }
    state.realmName = state.realmName.toLowerCase()
    return new Tozny.identity.Realm(
      state.realmName,
      'account',
      `${TOZID_HOSTNAME}/${state.realmName}/recover`,
      TOZNY_API_HOST
    )
  },
  apiUrlRoot(state) {
    return `${TOZNY_API_HOST}/auth/realms/${state.realmName}/account-api`
  },
  apiResourceUrl(state) {
    return `${TOZNY_API_HOST}/auth/realms/${state.realmName}`
  },
  verifiedRealm(state) {
    return state.realmVerified
  },
  passwordForgotLink(state) {
    return state.realmForgotPasswordLink
  },
  passwordForgotText(state) {
    return state.realmForgotPasswordText
  },
  externalIdPs(state) {
    return state.realmExternalIdps
  },
  preferredIDP(state) {
    return state.preferredExternalIDP
  },
  idpStateId(state) {
    return state.idpAuthInfo.state
  },
}

const mutations = {
  SET_REALM_NAME(state, realmName) {
    state.realmName = realmName
  },
  SET_MFA_OPTIONS(state, mfa) {
    state.mfaOptions = mfa
  },
  SET_IDENTITY(state, identity) {
    state.identity = identity
  },
  SET_LOGIN_ACTION(state, { type, fields, context }) {
    state.loginAction = type
    state.loginFields = fields
    state.loginContext = context
  },
  SET_LOGIN_ERROR(state, message) {
    state.loginError = message
  },
  SET_ERROR(state, message) {
    state.globalError = message
  },
  SET_LOGGING_IN(state, active) {
    state.loggingIn = active
  },
  CLEAR_IDENTITY(state) {
    state.identity = false
  },
  CLEAR_LOGIN_ACTION(state) {
    state.loginAction = ''
    state.loginFields = {}
    state.loginContext = {}
  },
  CLEAR_LOGIN_ERROR(state) {
    state.loginError = ''
  },
  SET_VERIFIED_REALM(state, set) {
    state.realmVerified = set
  },
  SET_PASSWORD_FORGOT_LINK(state, set) {
    state.realmForgotPasswordLink = set
  },
  SET_PASSWORD_FORGOT_TEXT(state, set) {
    state.realmForgotPasswordText = set
  },
  SET_EXTERNAL_IDPS_EXIST(state, set) {
    state.realmIdPsExist = set
  },
  SET_EXTERNAL_IDPS(state, set) {
    state.realmExternalIdps = set
  },
  SET_IDENTITY_PROVIDER_BASE_URL(state, url) {
    state.identityServiceProviderBaseURL = url
  },
  SET_EXTERNAL_IDP_LOGIN_PATH(state, path) {
    state.externalIDPLoginPath = path
  },
  SET_EXTERNAL_IDP_LOGIN_COOKIES(state, set) {
    state.externalIDPLoginCookies = set
  },
  // INITIALIZE_POTENTIAL_PREFERRED_IDP(state) {
  //   state.potentialPreferredExternalIDP = localStorage.getItem(
  //     'potential_preferred_external_idp'
  //   )
  // },
  // INITIALIZE_PREFERRED_IDP(state) {
  //   state.preferredExternalIDP = localStorage.getItem('preferred_external_idp')
  // },
  // SET_POTENTIAL_PREFERRED_IDP(state, set) {
  //   localStorage.setItem('potential_preferred_external_idp', set)
  //   state.potentialPreferredExternalIDP = set
  // },
  SET_PREFERRED_IDP(state, set) {
    // localStorage.setItem('preferred_external_idp', set)
    state.preferredExternalIDP = set
  },
  // CLEAR_IDP_PREFERENCES(state) {
  //   localStorage.setItem('preferred_external_idp', '')
  //   state.preferredExternalIDP = ''
  //   localStorage.setItem('potential_preferred_external_idp', '')
  //   state.potentialPreferredExternalIDP = ''
  // },
  SET_LOGIN_TYPE(state, set) {
    state.loginType = set
  },
  SET_IDP_AUTH_INFO(state, set) {
    state.idpAuthInfo = set
  },
}

const actions = {
  async verifyRealm({ commit, state }, realmName) {
    const realmNameDomain = realmName.toLowerCase()

    let realmToVerify = new Tozny.identity.Realm(
      realmNameDomain,
      'account',
      `${TOZID_HOSTNAME}/${state.realmName}/recover`,
      TOZNY_API_HOST
    )

    try {
      let response = await realmToVerify.info()
      commit('SET_VERIFIED_REALM', true)
      commit('SET_PASSWORD_FORGOT_LINK', response.forgot_password_custom_link)
      commit('SET_PASSWORD_FORGOT_TEXT', response.forgot_password_custom_text)
      commit('SET_EXTERNAL_IDPS_EXIST', response.do_idps_exist)
      commit(
        'SET_IDENTITY_PROVIDER_BASE_URL',
        response.identity_service_provider_base_url
      )
      if (response.do_idps_exist) {
        // In set realm name form, the current href wont have realm name.
        // This will by default set the redirect url back to set realm form page
        // instead of login page, hence set it to blank so that identity
        // service can set the default redirect url to login page
        // To do that we check if the current url has realm name, if not default to blank
        let url = window.location.href
        if (url.indexOf(response.name) < 0) {
          url = ''
        }

        // may be set default scope to include email & profile
        // so that 3rd party idp initiators can map appropriate profile
        // data when token is received but need to configure eidp client
        // to have those default scopes enabled

        // const scope = 'openid email profile'
        const urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())
        const scope = params.scope ? params.scope : ''

        const ret = await realmToVerify.initiateIdpLogin(url, scope)
        // Set Information in Cache
        commit('SET_EXTERNAL_IDPS', ret.context.idp_providers.providers)
        commit('SET_EXTERNAL_IDP_LOGIN_COOKIES', ret.cookies)
      }
    } catch (e) {
      commit('SET_VERIFIED_REALM', false)
    }
  },
  async clearIdps({ commit }) {
    commit('SET_EXTERNAL_IDPS', [])
    commit('SET_EXTERNAL_IDP_LOGIN_COOKIES', {})
  },
  async bootstrap({ commit, dispatch, state, getters }, realmName) {
    if (realmName === state.realmName) {
      return
    }
    dispatch('reset')
    commit('SET_REALM_NAME', realmName)
    if (!realmName) {
      return
    }
    const now = Date.now()
    let identityCache = loadCache(identityCacheKey(realmName))
    if (identityCache) {
      const realm = getters.realm
      const identity = realm.fromObject(identityCache)
      const info = await identity.agentInfo()
      commit('SET_PREFERRED_IDP', info.identity_provider)
      if (now > info.expiry) {
        await clearCache(identityCacheKey(state.realmName))
        await dispatch('reset')
        return
      }
      commit('SET_IDENTITY', identity)
      dispatch('fetchRealmSettings')
    }
  },
  async idpLogin({ state }, authUrl) {
    for (const cookie in state.externalIDPLoginCookies) {
      //clear previous cookies
      // document.cookie = `${cookie}=;Path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      document.cookie = `${cookie}=${state.externalIDPLoginCookies[cookie]};Path=/;`
    }
    window.location.href = `${state.identityServiceProviderBaseURL}${authUrl}`
  },
  async login({ state, getters, commit }, { username, password }) {
    try {
      commit('CLEAR_LOGIN_ERROR')
      commit('CLEAR_LOGIN_ACTION')
      commit('SET_LOGGING_IN', true)
      const realm = getters.realm
      if (!realm) {
        throw new Error('Realm is not set')
      }

      const identity = await realm.login(username, password, (err, data) => {
        return new Promise((resolve) => {
          commit('SET_LOGIN_ACTION', data)
          if (err !== null) {
            commit('SET_LOGIN_ERROR', err.message)
          } else {
            commit('CLEAR_LOGIN_ERROR')
          }
          commit('SET_LOGGING_IN', false)
          continuation = resolve
        })
      })
      setCache(identityCacheKey(state.realmName), identity.serialize())
      commit('SET_IDENTITY', identity)
      commit('CLEAR_LOGIN_ACTION')
    } catch (e) {
      commit('SET_LOGGING_IN', false)
      commit('CLEAR_LOGIN_ACTION')
      let message
      switch (e.name) {
        case 'InvalidCredentials':
          message =
            'Your credentials do not match our records. Please try again.'
          break
        case 'CredentialNoteError':
          message =
            e.response.status === 401
              ? 'An authorization error occurred while trying to load your ' +
                'identity. This could mean your identity information is out ' +
                'of sync. You could try resetting your password. If the ' +
                'problem persists, please contact your administrator.'
              : 'We were unable to load your identity. This is likely a ' +
                'problem on our end. Please try again later.'
          break
        case 'CredentialDataError':
          message =
            'There appears to be an issue with your identity data. You ' +
            'could try resetting your password. If this problem persists, ' +
            'please contact your administrator.'
          break
        case 'IdentityLockedError':
          message =
            'Your Identity is currently locked due to too many failed login attempts. ' +
            'Please try again later.'
          break
        case 'ClockDriftError':
          message =
            'Unsuccessful login. This could mean your credentials do not match our records or could be due to a system clock error. Please try again.'
          break
        case 'TooManyRequestsError':
          message = 'Too many requests, please try again later.'
          break
        default:
          message =
            `General service error: ${e.message}. This is likely a problem ` +
            'on our end. If the problem persists, please contact your ' +
            'administrator.'
      }
      commit('SET_LOGIN_ERROR', message)
    }
  },

  async doLoginAction({ state, commit }, data) {
    if (continuation !== null && state.loginAction) {
      commit('SET_LOGGING_IN', true)
      continuation(data)
    }
  },

  async checkPushComplete({ commit, dispatch }, id) {
    const request = await fetch(
      `${TOZNY_API_HOST}/v1/identity/challenge/${id}`,
      {
        method: 'GET',
      }
    )
    if (request.ok) {
      return true
    }
    let err
    switch (request.status) {
      case 401:
        err = 'The push challenge was rejected'
        break
      case 404:
        err = 'The push challenge was not found'
        break
      case 428:
        return false
      default:
        err = 'An unknown error occurred when checking push status'
        break
    }
    await dispatch('logout')
    commit('SET_LOGIN_ERROR', err)
  },

  async ssoLogin({ commit, state }, { actionURL }) {
    if (!state.identity) {
      return false
    }
    commit('SET_LOGGING_IN', true)
    const token = await state.identity.agentToken()
    const req = await fetch(actionURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!req.ok) {
      commit('SET_LOGGING_IN', false)
      commit('SET_LOGIN_ERROR', `Unable to complete login: ${req.statusText}`)
      return false
    }
    const challenge = await req.json()
    let nextAction = challenge.action
    const signedChallenge = await Tozny.crypto.sign(
      challenge.challenge,
      state.identity.storage.config.privateSigningKey
    )

    nextAction += '&challenge=' + signedChallenge
    commit('SET_LOGGING_IN', false)
    window.location.replace(nextAction)
    return true
  },

  clearCookies() {
    var cookies = document.cookie.split('; ')
    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split('.')
      while (d.length > 0) {
        var cookieBase =
          encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
          '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
          d.join('.') +
          ' ;path='
        var p = location.pathname.split('/')
        document.cookie = cookieBase + '/'
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/')
          p.pop()
        }
        d.shift()
      }
    }
  },

  async ssoIdpLogin({ commit, dispatch }, { actionURL, token }) {
    commit('SET_LOGGING_IN', true)
    commit('CLEAR_LOGIN_ERROR')
    dispatch('clearCookies')

    if (!token || !actionURL) {
      commit('SET_LOGGING_IN', false)
      return false
    }

    const req = await fetch(actionURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (!req.ok) {
      commit('SET_LOGGING_IN', false)
      commit('SET_LOGIN_ERROR', `Unable to complete login: ${req.statusText}`)
      return false
    }
    const challenge = await req.json()
    let nextAction = challenge.action
    // const signedChallenge = await Tozny.crypto.sign(
    //   challenge.challenge,
    //   state.identity.storage.config.privateSigningKey
    // )

    // nextAction += '&challenge=' + challenge.challenge
    nextAction += '&challenge=false'
    window.location.replace(nextAction)
    return true
  },

  async logout({ commit, dispatch, state }, waitForLogout = false) {
    if (!state.identity) {
      return
    }
    if(state.identity._agentToken.identity_provider) {
      await dispatch('idpLogout');
      return
    }
    const logoutFailHandler = (success) => {
      if (!success) {
        commit(
          'SET_LOGIN_ERROR',
          'You have been logged out, but the servers was unable to invalidate ' +
            'your session. You might try logging in again and removing the ' +
            'session in the sessions tab.'
        )
      }
    }
    // wait for the logout completion if asked, otherwise, fire and forget
    if (waitForLogout) {
      const success = await state.identity.logout()
      logoutFailHandler(success)
    } else {
      // fire now while identity is present, but don't await the result before
      // clearing state. If it fails we'll report the login error.
      state.identity.logout().then(logoutFailHandler)
    }
    await clearCache(identityCacheKey(state.realmName))
    await dispatch('reset')
    //await dispatch('clearIDPPreferences')
  },
  async forceLogout({ commit, dispatch, state }) {
    await dispatch('logout')
    if (state.loginError === '') {
      commit(
        'SET_LOGIN_ERROR',
        'Your session has expired, please log in again.'
      )
    }
  },
  async frontendLogout({ getters, dispatch, state }, params) {
    const realm = getters.realm
    if (!realm) {
      throw new Error('no realm available, unknown state.')
    }
    let redirect
    if (params.post_logout_redirect_uri) {
      redirect = realm.logoutRedirectUrl(params)
    }
    if (state.identity) {
      try {
        await dispatch('logout', true)
      } catch (e) {
        // if logout failed, report this is done, which will display the error
        return true
      }
    }
    if (redirect) {
      window.location = redirect
      // We are redirecting, report we are not done yet.
      return false
    }
    // We are done, report it and allow continued processing.
    return true
  },
  async idpLogout({ getters, state}) {
      const realm = getters.realm
      if (!realm) {
        throw new Error('no realm available, unknown state.')
      }
      const agent_token = await state.identity._tokenInfo.id_token
      let redirect = realm.logoutRedirectUrl({'id_token_hint': agent_token, 'id_portal_logout': 'true'})
      await clearCache(identityCacheKey(state.realmName))
      window.location = redirect
  },
  reset({ commit, dispatch }) {
    commit('CLEAR_IDENTITY')
    commit('CLEAR_LOGIN_ACTION')
    commit('CLEAR_LOGIN_ERROR')
    commit('SET_LOGGING_IN', false)
    commit('SET_VERIFIED_REALM', false)
    dispatch('sessions/reset')
    dispatch('applications/reset')
    dispatch('mfa/reset')
    dispatch('secrets/reset')
    dispatch('nav/reset')
    dispatch('accessRequests/reset')
  },
  async fetchRealmSettings({ commit, dispatch, rootState }) {
    try {
      const identity = rootState.identity
      let realmPrivateInfo = await tozny.realmPrivateInfo(identity)
      commit('SET_MFA_OPTIONS', realmPrivateInfo.mfa_available)
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    }
  },
  // async setPotentialPreferredIDP({ commit }, potentialIDPName) {
  //   commit('SET_POTENTIAL_PREFERRED_IDP', potentialIDPName)
  //   commit('SET_PREFERRED_IDP', '')
  // },
  // async setPotentialPreferredIDPFromPreferredIDP({ commit, state }) {
  //   commit('SET_POTENTIAL_PREFERRED_IDP', state.preferredExternalIDP)
  //   commit('SET_PREFERRED_IDP', '')
  // },
  // async setPreferredIDPFromPotentialPreferredIDP({ commit, state }) {
  //   const val = state.potentialPreferredExternalIDP
  //   commit('SET_PREFERRED_IDP', val)
  //   commit('SET_POTENTIAL_PREFERRED_IDP', '')
  // },
  // async setPreferredIDP({ commit, state }) {
  //   commit('SET_PREFERRED_IDP', state.potentialPreferredExternalIDP)
  //   commit('SET_POTENTIAL_PREFERRED_IDP', '')
  // },
  // async clearIDPPreferences({ commit }) {
  //   commit('CLEAR_IDP_PREFERENCES')
  // },
  async getPreferredExternalIDPLink({ state }) {
    const preferredExternalIDP = state.preferredExternalIDP
    if (preferredExternalIDP || preferredExternalIDP != '') {
      const foundIdp = state.realmExternalIdps.find(
        (idp) => idp.alias == preferredExternalIDP
      )
      if (foundIdp) {
        return foundIdp.loginUrl
      }
    }
    return undefined
  },
  async EIDPToznyLogin({ getters, commit }, token) {
    commit('SET_LOGGING_IN', true)
    // Call method to register this identity.
    const realm = getters.realm
    if (!realm) {
      throw new Error('Realm is not set')
    }
    const {username, password, restrictedLogin} = await realm.eidpCreateToznyIdentity(token.access_token)
    if(username !== "") {
      const identity = await realm.completeIdpLogin(username, password, token)
      setCache(identityCacheKey(state.realmName), identity.serialize())
      commit('SET_IDENTITY', identity)
      commit('CLEAR_LOGIN_ACTION')
    } else if (restrictedLogin) {
      commit('SET_LOGGING_IN', false)
      commit('CLEAR_LOGIN_ACTION')
      commit('SET_IDENTITY', null)
    } else {
      commit('SET_LOGGING_IN', false)
      commit('CLEAR_LOGIN_ACTION')
      commit('SET_LOGIN_ERROR', "Something went wrong. Please try again.")
    }
  },
  setLoginType({commit}, type) {
    commit('SET_LOGIN_TYPE', type)
  },
  setIdpAuthInfo({commit}, authInfo){
    commit('SET_IDP_AUTH_INFO', authInfo)
  }
}

export default new Vuex.Store({
  modules,
  state,
  getters,
  mutations,
  actions,
})
