import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const HTML_TITLE_BASE = global.HTML_TITLE_BASE
const uuidRegEx =
  '[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}'

const router = new Router({
  routes: [
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => {
        return import('../Pages/Forbidden/index.vue')
      },
      meta: {
        requiresNoAuth: true,
        title: 'Forbidden',
      },
    },
    {
      // Path to finish the oidc flow in the identity portal
      path: '/:realmName([a-zA-Z0-9]{1,30})',
      name: 'authLayer',
      component: () => {
        return import(
          /* webpackChunkName: "authLayer" */ '../Pages/AuthLayer/index.vue'
        )
      },
      children: [
        // Authenticated Routes
        {
          path: '',
          name: 'profile',
          component: () => {
            return import(
              /* webpackChunkName: "profile" */ '../Pages/Portal/index.vue'
            )
          },
          meta: {
            requiresAuth: true,
            title: 'Profile',
            menu: 'profile',
          },
        },
        {
          path: 'account',
          redirect: { name: 'profile' },
        },
        {
          path: 'security',
          name: 'security',
          component: () => {
            return import(
              /* webpackChunkName: "security" */ '../Pages/Portal/Security.vue'
            )
          },
          meta: {
            requiresAuth: true,
            title: 'Change Password',
            menu: 'security',
          },
        },
        {
          path: 'applications',
          name: 'application-grid',
          component: () => {
            return import(
              /* webpackChunkName: "applications" */ '../Pages/Portal/Applications.vue'
            )
          },
          meta: {
            requiresAuth: true,
            title: 'Applications',
            menu: 'applications',
          },
        },
        {
          path: 'sessions',
          name: 'session-list',
          component: () => {
            return import(
              /* webpackChunkName: "sessions" */ '../Pages/Portal/Sessions.vue'
            )
          },
          meta: {
            requiresAuth: true,
            title: 'Sessions',
            menu: 'sessions',
          },
        },
        {
          path: `secrets/:groupIndex/:recordId(${uuidRegEx})`,
          name: 'single-secret',
          component: () => {
            return import(
              /* webpackChunkName: "singleSecret" */ '../Pages/Portal/Secrets/index.vue'
            )
          },
          meta: {
            requiresAuth: true,
            title: 'Secrets',
            menu: 'secrets',
          },
        },
        {
          path: 'secrets',
          name: 'secrets-list',
          component: () => {
            return import(
              /* webpackChunkName: "secrets" */ '../Pages/Portal/Secrets/index.vue'
            )
          },
          meta: {
            requiresAuth: true,
            title: 'Secrets',
            menu: 'secrets',
          },
        },
        {
          path: 'access-requests/:accessRequestId([0-9]+)',
          name: 'access-request-detail',
          component: () => {
            return import(
              /* webpackChunkName: "accessRequests" */ '../Pages/Portal/AccessRequests/DetailedView.vue'
            )
          },
          meta: {
            requiresAuth: true,
            title: 'Access Request Details',
            menu: 'access requests',
          },
        },
        {
          path: 'access-requests',
          name: 'access-requests',
          component: () => {
            return import(
              /* webpackChunkName: "accessRequests" */ '../Pages/Portal/AccessRequests/index.vue'
            )
          },
          meta: {
            requiresAuth: true,
            title: 'Access Requests',
            menu: 'access requests',
          },
        },

        // No Authentication Routes
        {
          // Path to begin the oidc flow in the identity portal
          path: 'login',
          name: 'login',
          component: () => {
            return import(
              /* webpackChunkName: "login" */ '../Pages/Login/index.vue'
            )
          },
          meta: {
            requiresNoAuth: true,
            processLogin: true,
            title: 'Login',
          },
        },
        {
          path: 'verify',
          name: 'complete-login',
          component: () => {
            return import(
              /* webpackChunkName: "login" */ '../Pages/Login/index.vue'
            )
          },
          meta: {
            requiresNoAuth: true,
            title: 'Login',
          },
        },
        {
          path: 'forgot',
          name: 'forgot-password-realm',
          component: () => {
            return import(
              /* webpackChunkName: "forgot-password" */ '../Pages/RecoverPassword/index.vue'
            )
          },
          meta: {
            requiresNoAuth: true,
            title: 'Forgot Password',
          },
        },
        {
          path: 'recover',
          name: 'recover-password-realm',
          component: () => {
            return import(
              /* webpackChunkName: "recover-password" */ '../Pages/RecoverPassword/Complete.vue'
            )
          },
          meta: {
            requiresNoAuth: true,
            title: 'Recover Password',
          },
        },

        // Public Routes
        {
          path: 'logout',
          name: 'logout',
          component: () => {
            return import(
              /* webpackChunkName: "login" */ '../Pages/404/index.vue'
            )
          },
          meta: {
            requiresNotLoggedIn: false,
            title: 'Page Not Found',
          },
        },
        {
          path: '*',
          name: 'not-found',
          component: () => {
            return import(
              /* webpackChunkName: "login" */ '../Pages/404/index.vue'
            )
          },
          meta: {
            requiresNotLoggedIn: false,
            title: 'Page Not Found',
          },
        },
      ],
    },
    {
      path: '/',
      name: 'no-realm',
      component: () => {
        return import(
          /* webpackChunkName: "no-realm" */ '../Pages/NoRealm/index.vue'
        )
      },
      meta: {
        title: 'Set Realm',
      },
    },
  ],
  mode: 'history',
})

/**
 * Route guards that run before each route change to restrict access to routes based
 * upon desired criteria (e.g. authenticated, account not expired/blacklisted)
 *
 */
router.beforeEach(async (to, _from, next) => {
  const metaTitle = to.meta.title
  const title = metaTitle
    ? `${HTML_TITLE_BASE} - ${metaTitle}`
    : HTML_TITLE_BASE

  Vue.nextTick(() => {
    document.title = title
  })

  next()
})

export default router
