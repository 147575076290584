<template>
  <router-view v-if="browserSupported === true" />
  <AuthenticationForm v-else-if="browserSupported === false">
    <div class="p-4">
      <h3 class="mb-4">We're sorry...</h3>
      <p>We have detected missing features in your browser.</p>
      <p>
        These features are required for the Tozny Identity portal to run. To
        continue please switch to a supported browser or enable WebAssembly and
        <code>window.crypto</code> with PBKDF2.
      </p>
      <hr class="border-gray-light mb-4" />
      <p class="mb-2"><strong>Supported browsers include:</strong></p>
      <ul class="list-disc pl-4">
        <li>Google Chrome</li>
        <li>Firefox</li>
        <li>Safari</li>
        <li>Android Browser</li>
      </ul>
    </div>
  </AuthenticationForm>
</template>

<script>
import Tozny from '@toznysecure/sdk/browser'
import AuthenticationForm from '@/Common/AuthenticationFrom/AuthenticationForm'
export default {
  name: 'app',
  components: {
    AuthenticationForm,
  },
  data() {
    return {
      browserSupported: null,
    }
  },
  async created() {
    const supported = await Tozny.helpers.checkCrypto()
    this.browserSupported = supported
  },
}
</script>
<style lang="scss">
@import '@/scss/app.scss';
</style>
