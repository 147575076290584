import { isUnauthorized, rootIdentity } from '../../utils/utils'
/**
 * State associated with active sessions.
 */

/**
 * state and actions surrounding identity sessions
 * @module sessions
 */

/** initial session state */
const state = {
  /** the account's current billing status */
  status: 'initializing',
  sessions: [],
  errorMessage: '',
  expireQueue: {},
}

/** cache-able getters for the current sessions for the active identity */
const getters = {
  hasError: (state) => {
    return !!state.errorMessage
  },
}

/** synchronous mutations of tokens state */
const mutations = {
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_SESSIONS(state, { sessions }) {
    state.sessions = sessions
  },
  SET_ERROR(state, { error, status }) {
    state.errorMessage = error
    state.status = status
  },
  ENQUEUE_EXPIRE(state, session) {
    state.expireQueue = Object.assign({ [session]: true }, state.expireQueue)
  },
  EXPIRATION_COMPLETE(state, session) {
    const newQueue = state.expireQueue
    delete state.expireQueue[session]
    state.expireQueue = newQueue
  },
  CLEAR_SESSIONS(state) {
    state.sessions = []
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
  CLEAR_EXPIRE_QUEUE() {
    state.expireQueue = {}
  },
}

const statuses = ['initializing', 'loading', 'idle', 'error']

/**
 * Callable state transitions that facilitate async actions and state transitions
 */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', { error: 'Error: Unknown state', status: 'error' })
    }
  },
  /**
   * Syncs the state machine with the application state, fetching as necessary.
   * @param {context} param0 The vuex state context
   */
  async initialize({ dispatch, state }) {
    // Only act on initializing state
    if (state.status !== 'initializing') {
      return
    }
    // Determine if initial fetch is necessary
    if (!state.sessions.length) {
      await dispatch('transitionStatus', 'loading')
      await dispatch('loadSessions')
    } else {
      await dispatch('transitionStatus', 'idle')
    }
  },
  async loadSessions({ commit, dispatch, rootState, rootGetters }) {
    try {
      const identity = rootIdentity(rootState)
      const request = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/active-sessions`,
        {
          headers: {
            Accept: 'application/json',
          },
        }
      )
      // Visually hide the session application as it is not useful information for display.
      let sessions = await request.json()
      sessions = sessions.map((s) => {
        s.clients = s.clients.filter((c) => c.clientId !== 'tozid-realm-idp')
        return s
      })
      commit('SET_SESSIONS', { sessions })
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    }
  },
  async expireSession(
    { commit, dispatch, state, rootState, rootGetters },
    session
  ) {
    const identity = rootIdentity(rootState)

    const sessions = []
    try {
      commit('ENQUEUE_EXPIRE', session)
      const request = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/active-sessions/${session}`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
          },
        }
      )
      if (!request.ok) {
        throw new Error(`${request.statusText}: unable to expire session`)
      }
      for (let s of state.sessions) {
        if (s.id !== session) {
          sessions.push(s)
        } else if (s.current) {
          dispatch('forceLogout', null, { root: true })
          return
        }
      }
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    } finally {
      commit('SET_SESSIONS', { sessions })
      commit('EXPIRATION_COMPLETE', session)
    }
  },
  async expireAll({ commit, dispatch, rootState, rootGetters }) {
    const identity = rootIdentity(rootState)
    try {
      await dispatch('transitionStatus', 'loading')
      const request = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/active-sessions`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
          },
        }
      )
      if (!request.ok) {
        throw new Error(`${request.statusText}: unable to expire sessions`)
      }
      const sessions = await request.json()
      commit('SET_SESSIONS', { sessions })
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    }
  },
  async reset({ commit, dispatch }) {
    await dispatch('transitionStatus', 'initializing')
    commit('CLEAR_SESSIONS')
    commit('CLEAR_ERROR')
    commit('CLEAR_EXPIRE_QUEUE')
  },
}

export default {
  // namespace this modules actions, mutations, and getters under 'sessions' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
