/**
 * State associated with secrets.
 */

import tozny from '../../api/tozny'
import { isUnauthorized, rootIdentity } from '../../utils/utils'

/**
 * state and actions surrounding identity secrets
 * @module secrets
 */
const PAGE_SIZE_MAX = 20

/** initial session state */
const state = {
  status: 'initializing',
  sessions: [],
  secrets: [],
  groupedSecrets: {},
  errorMessage: '',
  successMessage: '',
  initialized: false,
  nextToken: undefined,
  currentSecret: {},
  currentUser: '',
  types: [],
  shareList: [],
  selectedShareUsername: '',
  selectedRemoveUsername: '',
  fileUrl: '',
}

const statuses = [
  'loading',
  'initializing',
  'idle',
  'manage.add',
  'manage.view',
  'manage.edit',
  'manage.error',
  'manage.loading',
  'manage.idle',
  'adding',
  'manage.addShare',
  'manage.removeShare',
  'manage.delete',
  'manage.deleting',
  'globalError',
]

/** cache-able getters for the secrets created by active identity */
const getters = {
  hasError(state) {
    return !!state.errorMessage
  },
  hasMore(state) {
    return state.nextToken !== 0
  },
  secretID(state) {
    return state.selectedSecretID
  },
}

/** synchronous mutations of secrets state */
const mutations = {
  INITIALIZED(state) {
    state.initialized = true
  },
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_ERROR(state, { error, status }) {
    state.errorMessage = error
    state.status = status
  },
  SET_SECRET(state, secret) {
    state.currentSecret = secret
  },
  SET_SUCCESS_MESSAGE(state, message) {
    state.successMessage = message
  },
  CLEAR_SUCCESS_MESSAGE(state) {
    state.successMessage = ''
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
  ADD_SECRET(state, secretList) {
    state.secrets = [...state.secrets, ...secretList]
  },
  SET_SECRETS(state, secretList) {
    state.secrets = secretList
  },
  SET_GROUPED_SECRETS(state, secretList) {
    state.groupedSecrets = secretList
  },
  CLEAR_SECRETS(state) {
    state.secrets = []
  },
  CLEAR(state) {
    state.secrets = []
    state.status = 'initializing'
    state.errorMessage = ''
    state.types = []
    state.currentUser = ''
    state.currentSecret = {}
    state.nextToken = undefined
    state.shareList = []
    state.errorMessage = ''
    state.successMessage = ''
    state.initialized = false
    state.fileUrl = ''
  },
  SET_NEXT_TOKEN(state, nextToken) {
    state.nextToken = nextToken
  },
  SET_CURRENT_USER(state, userId) {
    state.currentUser = userId
  },
  SET_TYPES(state, types) {
    state.types = types
  },
  SET_SELECTED_SECRET(state, secretID) {
    state.selectedSecretID = secretID
  },
  CLEAR_SECRET(state) {
    state.selectedSecretID = ''
  },
  SET_SELECTED_SHARE_USERNAME(state, username) {
    state.selectedShareUsername = username
  },
  CLEAR_SELECTED_SHARE_USERNAME(state) {
    state.selectedShareUsername = ''
  },
  ADD_SHARE(state, shareList) {
    state.shareList = [...state.shareList, ...shareList]
  },
  SET_SHARE_LIST(state, { shareList }) {
    state.shareList = shareList
  },
  CLEAR_SHARED(state) {
    state.shareList = []
  },
  SET_SELECTED_REMOVE_USERNAME(state, username) {
    state.selectedRemoveUsername = username
  },
  CLEAR_SELECTED_REMOVE_USERNAME(state) {
    state.selectedRemoveUsername = ''
  },
  SET_URL(state, url) {
    state.fileUrl = url
  },
}

/**
 * Callable state transitions that facilitate async actions and state transitions
 */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', { error: 'Error: Unknown state', status: 'error' })
    }
  },
  async initializeSecrets({ commit, dispatch, rootState }) {
    await dispatch('transitionStatus', 'idle')
    await dispatch('getSecretTypes')
    await dispatch('getSecrets')
    const fetchedSecrets = state.secrets
    if (!fetchedSecrets) {
      commit('SET_ERROR', {
        message: 'No Secrets Were Found. If this wrong, reload the page',
        status: 'error',
      })
      return
    }

    try {
      const identity = rootIdentity(rootState)
      commit('SET_CURRENT_USER', identity.storage.config.clientId)
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'globalError' })
      }
    }
  },
  async createSecret({ commit, dispatch, rootState }, secret) {
    commit('CLEAR_SUCCESS_MESSAGE')
    commit('CLEAR_ERROR')
    try {
      await dispatch('transitionStatus', 'manage.loading')
      const identity = rootIdentity(rootState)
      let secretMade = await tozny.createSecret(identity, secret)
      await dispatch('getSecrets')
      commit('SET_SECRET', secretMade)
      commit(
        'SET_SUCCESS_MESSAGE',
        `Secret ${secretMade.meta.plain.secretName} added successfully!`
      )
      await dispatch('transitionStatus', 'manage.view')
      return secretMade
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'manage.add' })
      }
    }
  },
  async updateSecret({ commit, dispatch, rootState }, newSecret) {
    commit('CLEAR_SUCCESS_MESSAGE')
    try {
      const old = state.currentSecret
      let value =
        old.meta.plain.secretType === 'File' ? '' : old.data.secretValue
      const oldSecret = {
        secretType: old.meta.plain.secretType,
        secretName: old.meta.plain.secretName,
        secretValue: value,
        fileName: old.meta.plain.fileName,
        description: old.meta.plain.description,
      }
      const identity = rootIdentity(rootState)
      if (newSecret.secretType === 'File' && newSecret.fileName === undefined) {
        newSecret.fileName = oldSecret.fileName
        const blob = await tozny.getFileBlob(identity, old.meta.recordId)
        newSecret.file = blob
      }
      let secretMade = await tozny.updateSecret(identity, oldSecret, newSecret)
      await dispatch('getSecrets')
      commit('SET_SECRET', secretMade)
      commit(
        'SET_SUCCESS_MESSAGE',
        `Secret ${oldSecret.secretName} updated successfully!`
      )
      await dispatch('transitionStatus', 'manage.view')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'manage.edit' })
      }
    }
  },
  async setSecret({ commit }, secret) {
    commit('SET_SECRET', secret)
  },
  async initializeSecret({ commit, rootState, dispatch }, secretID) {
    try {
      await dispatch('transitionStatus', 'manage.loading')
      if (secretID == null) {
        await dispatch('transitionStatus', 'idle')
        return
      }
      const identity = rootIdentity(rootState)
      const secretResponse = await tozny.viewSecret(identity, secretID)
      const secret = secretResponse.secret
      if (!secret) {
        commit('SET_ERROR', {
          message: `A secret with ID ${secretID} was not found`,
          status: 'manage.error',
        })
        return
      }
      if (secret.meta.plain.secretType === 'File') {
        await dispatch('downloadFile', secret.meta.recordId)
      }
      commit('SET_SECRET', secret)
      await dispatch('getShareList')
      await dispatch('transitionStatus', 'manage.view')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'globalError' })
      }
    }
  },
  async groupSecrets({ commit }, secrets) {
    let groupedSecrets = {}
    if (!secrets || secrets.length == 0) {
      return
    }
    secrets.forEach((secret) => {
      let rawData = {
        name: secret.meta.plain['secretName'],
        type: secret.meta.plain['secretType'],
        userName: secret.meta['username'],
        userId: secret.meta['userId'],
        owner:
          secret.meta['userId'] === state.currentUser
            ? 'You'
            : secret.meta['username'],
        shared: secret.meta['shared'],
        secretId: secret.meta.recordId,
        lastestVersion: secret.meta.plain['version'],
        latestRecord: secret,
      }
      const key = `${rawData.name}-${rawData.type}-${rawData.userId}`
      rawData['groupIndex'] = key
      if (!groupedSecrets[key]) {
        groupedSecrets[key] = { versions: [] }
      }
      groupedSecrets[key] = { ...groupedSecrets[key], ...rawData }
      groupedSecrets[key].versions.push(secret)
    })
    commit('SET_GROUPED_SECRETS', groupedSecrets)
  },
  async getSecrets({ commit, dispatch, rootState }) {
    await dispatch('transitionStatus', 'loading')
    try {
      const identity = rootIdentity(rootState)
      let secretList = await tozny.getSecrets(
        identity,
        PAGE_SIZE_MAX,
        state.nextToken
      )
      let listOfSecrets = secretList.list
      commit('SET_SECRETS', listOfSecrets)
      await dispatch('groupSecrets', listOfSecrets)
      commit('SET_NEXT_TOKEN', secretList.nextToken)
      await dispatch('transitionStatus', 'manage.idle')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = 'Backend server error trying to list secrets'
        commit('SET_ERROR', { error, status: 'globalError' })
      }
    }
  },
  getSecretTypes({ rootState, dispatch, commit }) {
    const identity = rootIdentity(rootState)
    try {
      const types = tozny.secretTypes(identity)
      commit('SET_TYPES', types)
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'globalError' })
      }
    }
  },
  async clearMessage({ commit }) {
    commit('CLEAR_SUCCESS_MESSAGE')
  },
  async clearError({ commit }) {
    commit('CLEAR_ERROR')
  },
  async shareSecretWithUsername({ commit, dispatch, rootState }, username) {
    await dispatch('transitionStatus', 'manage.addShare')
    try {
      if (state.shareList.indexOf(username) >= 0) {
        const error = `Secret has already been shared with ${username}`
        commit('SET_ERROR', { error, status: 'globalError' })
        return
      }
      const identity = rootIdentity(rootState)
      const secretName = state.currentSecret.meta.plain.secretName
      const secretType = state.currentSecret.meta.plain.secretType
      const returnVal = await tozny.shareSecretWithUsername(
        identity,
        secretName,
        secretType,
        username
      )
      if (returnVal === null) {
        const error = 'Not A Valid Username'
        commit('SET_ERROR', { error, status: 'manage.view' })
      } else {
        commit('ADD_SHARE', [username])
        commit(
          'SET_SUCCESS_MESSAGE',
          `Secret shared with ${username} successfully!`
        )
        await dispatch('getSecrets')
        await dispatch('transitionStatus', 'manage.view')
      }
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = 'Backend server error sharing secret'
        commit('SET_ERROR', { error, status: 'globalError' })
      }
    }
  },
  async getShareList({ commit, dispatch, rootState }) {
    try {
      const identity = rootIdentity(rootState)
      const secretName = state.currentSecret.meta.plain.secretName
      const secretType = state.currentSecret.meta.plain.secretType
      const shareListResponse = await tozny.getSecretSharedList(
        identity,
        secretName,
        secretType
      )
      let sharedWithList = shareListResponse.list
      let shareList = []
      for (let index = 0; index < sharedWithList.length; index++) {
        shareList.push(sharedWithList[index].username)
      }
      commit('CLEAR_SHARED')
      commit('SET_SHARE_LIST', { shareList })
      await dispatch('transitionStatus', 'manage.view')
      return state.shareList
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = 'Backend server error trying to list shares'
        commit('SET_ERROR', { error, status: 'globalError' })
      }
    }
  },
  async revokeShare({ commit, rootState, dispatch }) {
    try {
      dispatch('transitionStatus', 'manage.loading')
      let username = state.selectedRemoveUsername
      const identity = rootIdentity(rootState)
      const secretName = state.currentSecret.meta.plain.secretName
      const secretType = state.currentSecret.meta.plain.secretType
      await tozny.revokeRecordWithGroup(
        identity,
        secretName,
        secretType,
        username
      )
      commit('CLEAR_SELECTED_REMOVE_USERNAME')
      await dispatch('getShareList')
      await dispatch('getSecrets')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = 'Backend server error unsharing secret'
        commit('SET_ERROR', { error, status: 'globalError' })
      }
    }
  },
  async setToRevoke({ commit, dispatch }, username) {
    commit('SET_SELECTED_REMOVE_USERNAME', username)
    await dispatch('transitionStatus', 'manage.removeShare')
  },
  async removeFromRevoke({ commit, dispatch }) {
    commit('CLEAR_SELECTED_REMOVE_USERNAME')
    await dispatch('transitionStatus', 'manage.view')
  },
  async downloadFile({ rootState, dispatch, commit }, recordId) {
    try {
      const identity = rootIdentity(rootState)
      const result = await tozny.downloadFile(identity, recordId)
      commit('SET_URL', result)
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'globalError' })
      }
    }
  },
  async deleteSingleSecret({ commit, rootState, dispatch }) {
    try {
      dispatch('transitionStatus', 'manage.deleting')
      const identity = rootIdentity(rootState)
      await tozny.deleteSingleSecret(identity, state.currentSecret)
      await dispatch('getSecrets')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'manage.view' })
      }
    }
  },
  async reset({ commit, dispatch }) {
    await dispatch('transitionStatus', 'initializing')
    commit('CLEAR')
    commit('CLEAR_SELECTED_SHARE_USERNAME')
    commit('CLEAR_SELECTED_REMOVE_USERNAME')
  },
}

export default {
  // namespace this modules actions, mutations, and getters under 'secrets' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
