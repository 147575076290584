/**
 * State associated with active mfa.
 */

/**
 * state and actions surrounding identity mfa
 * @module mfa
 */

import Tozny from '@toznysecure/sdk/browser'
import { credentialedDecodeResponse } from '@toznysecure/sdk/lib/utils'
import qrCode from 'qrcode-generator'
import {
  checkStatus,
  handleMFAError,
  identityFetchStatus,
  isUnauthorized,
  rootIdentity,
} from '../../utils/utils'

const TOZNY_API_HOST = global.API_URL

const webauthnStatuses = {
  INITIALIZING: 'initializing',
  INITIALIZED: 'initialized',
  LOADING: 'loading',
}

const statuses = ['initializing', 'loading', 'idle', 'idle.submitting', 'error']

/** initial session state */
const state = {
  /** the account's current billing status */
  status: 'initializing',
  type: 'none',
  secret: '',
  qrCode: '',
  deviceRegistration: '',
  pushQRReady: false,
  policy: {},
  errorMessage: '',

  /**
   * HARDARE DEVICE MFA STATE
   */
  webauthnStatus: webauthnStatuses.INITIALIZING,
  webauthnErrorMessage: '',
  mfaTOTPDevices: [],
  mfaWebauthnDevices: [],
  hasMfaAlertInfo: false,
  mfaAlertTitle: '',
  mfaAlertMessage: '',
  mfaErrorMessage: '',
}
/** cache-able getters for the current mfa for the active identity */
const getters = {
  hasError: (state) => {
    return !!state.errorMessage
  },
  isIdle: (state) => {
    return state.status.substring(0, 4) === 'idle'
  },
  pushQRReady: (state) => {
    return state.pushQRReady
  },
  qrCodeImg: (state) => {
    const qr = qrCode(0, 'L')
    qr.addData(state.qrCode)
    qr.make()
    return qr
      .createSvgTag(100, 0)
      .replace(/(<svg .*?)( width=".*?" height=".*?")/, '$1')
  },
  pushQRCodeImg: (state) => {
    const qr = qrCode(0, 'L')
    qr.addData(state.deviceRegistration)
    qr.make()
    return qr
      .createSvgTag(100, 0)
      .replace(/(<svg .*?)( width=".*?" height=".*?")/, '$1')
  },

  /**
   * HARDWARE MFA DEVICE GETTERS
   */

  userHasWebAuthnRegistered: (state) => {
    return state.mfaWebauthnDevices.length > 0
  },
  webauthnInitialized: (state) =>
    state.webauthnStatus === webauthnStatuses.INITIALIZED,
}

/** synchronous mutations of tokens state */
const mutations = {
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_DEVICE_REGISTRATION(state, reg) {
    state.deviceRegistration = reg
    state.pushQRReady = true
  },
  CLEAR_DEVICE_REGISTRATION(state) {
    state.deviceRegistration = ''
    state.pushQRReady = false
  },
  SET_MFA(state, mfa) {
    state.type = mfa.type
    // If type is set, MFA is configured, do not take in secrets, etc.
    // if (state.type !== 'none') {
    //   state.secret = ''
    //   state.qrCode = ''
    //   state.policy = {}
    //   return
    // }
    if (mfa.secret) {
      state.secret = mfa.secret
    }
    if (mfa.qrCode) {
      state.qrCode = mfa.qrCode
    }
    if (mfa.policy) {
      state.policy = mfa.policy
    }
  },
  SET_ERROR(state, { error, status }) {
    state.errorMessage = error
    state.status = status
  },
  SET_MFA_ERROR(state, { error }) {
    state.mfaErrorMessage = error
  },
  CLEAR_MFA_ERROR(state) {
    state.mfaErrorMessage = ''
  },
  CLEAR_MFA(state) {
    state.type = 'none'
    state.secret = ''
    state.qrCode = ''
    state.policy = {}
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },

  /**
   * HARDWARE MFA DEVICE MUTATIONS
   */
  SET_WEBAUTHN_ERROR(state, error) {
    state.webauthnErrorMessage = error
  },
  SET_WEBAUTHN_STATUS(state, newStatus) {
    state.webauthnStatus = newStatus
  },
  SET_MFA_DEVICES(state, devices) {
    state.mfaTOTPDevices = devices.mfaDevices.totp
    state.mfaWebauthnDevices = devices.mfaDevices.webauthn
  },
  CLEAR_WEBAUTHN(state) {
    state.webauthnErrorMessage = ''
    state.mfaTOTPDevices = []
    state.mfaWebauthnDevices = []
  },
  SET_MFA_ALERT(state, { title, message }) {
    state.hasMfaAlertInfo = true
    state.mfaAlertTitle = title
    state.mfaAlertMessage = message
  },
  CLEAR_MFA_ALERT(state) {
    state.hasMfaAlertInfo = false
    state.mfaAlertTitle = ''
    state.mfaAlertMessage = ''
  },
}

/**
 * Callable state transitions that facilitate async actions and state transitions
 */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', { error: 'Error: Unknown state', status: 'error' })
    }
  },
  /**
   * Syncs the state machine with the mfa state, fetching as necessary.
   * @param {context} param0 The vuex state context
   */
  async initialize({ dispatch, state }) {
    // Only act on initializing state
    if (state.status !== 'initializing') {
      return
    }
    // Determine if initial fetch is necessary
    if (!state.type !== 'none' || state.secret !== '') {
      await dispatch('transitionStatus', 'loading')
      await dispatch('loadMfa')
    } else {
      await dispatch('transitionStatus', 'idle')
    }
  },
  async checkMFASetup({ commit, dispatch, rootState, rootGetters }) {
    try {
      const identity = rootIdentity(rootState)
      const request = await identity.fetch(`${rootGetters['apiUrlRoot']}/mfa`, {
        headers: {
          Accept: 'application/json',
        },
      })
      checkStatus(request, 'Failed to fetch multi-factor authentication data.')
      const mfa = await request.json()
      if (mfa.type === 'none') {
        return false
      }
      commit('SET_MFA', mfa)
      return true
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
        return true
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
        return true
      }
    }
  },
  async loadMfa({ commit, dispatch, rootState, rootGetters }) {
    try {
      const identity = rootIdentity(rootState)
      const request = await identity.fetch(`${rootGetters['apiUrlRoot']}/mfa`, {
        headers: {
          Accept: 'application/json',
        },
      })
      checkStatus(request)
      const mfa = await request.json()
      commit('SET_MFA', mfa)
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    }
  },
  async setupTotp({ commit, dispatch, state, rootState, rootGetters }, data) {
    const { totp, authenticatorLabel } = data
    commit('CLEAR_ERROR')
    await dispatch('transitionStatus', 'idle.submitting')
    try {
      const identity = rootIdentity(rootState)
      if (authenticatorLabel === '') {
        throw new Error('You must enter authenticator name')
      }
      if (totp === '') {
        throw new Error('You must enter a one-time code')
      }
      if (totp.length !== state.policy.digits) {
        throw new Error(
          `One-time code should be ${state.policy.digits} digits long`
        )
      }
      const request = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/mfa/totp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            secret: state.secret,
            totp,
            userLabel: authenticatorLabel,
          }),
        }
      )
      checkStatus(request, `Unable to complete setup: ${request.statusText}`)
      commit('SET_MFA', { type: 'totp' })
      await dispatch('transitionStatus', 'idle')
      dispatch('fetchMFADevices')
      return true
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'idle' })
      }
      return false
    }
  },
  async removeMFA({ commit, dispatch, rootState, rootGetters }) {
    await dispatch('transitionStatus', 'loading')
    try {
      const identity = rootIdentity(rootState)
      const request = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/mfa/remove`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
          },
        }
      )
      checkStatus(request, `Unable to complete removal: ${request.statusText}`)
      commit('CLEAR_DEVICE_REGISTRATION')
      throw new Error('Successfully removed your MFA!')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    }
  },
  async setupMobilePush({ commit, dispatch, rootState }) {
    try {
      const identity = rootIdentity(rootState)
      let signingKeys = await Tozny.crypto.generateSigningKeypair()
      let encryptionKeys = await Tozny.crypto.generateKeypair()
      let requestBody = JSON.stringify({
        temporary_public_key: signingKeys.publicKey,
        temporary_public_encryption_key: encryptionKeys.publicKey,
      })
      const request = await identity.storage.authenticator.tsv1Fetch(
        `${TOZNY_API_HOST}/v1/identity/register/device`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
          },
          body: requestBody,
        }
      )
      const registrationResponse = await credentialedDecodeResponse(request)
      let deviceRegistrationInfo = {
        action: 'register',
        data: {
          registration_id: registrationResponse.registration_id,
          api_host: TOZNY_API_HOST,
          temporary_private_key: signingKeys.privateKey,
          temporary_private_encryption_key: encryptionKeys.privateKey,
          temporary_public_encryption_key: encryptionKeys.publicKey,
          realm: identity.config.realmName,
          username: identity.config.username,
          totp_secret: registrationResponse.encrypted_totp_secret,
        },
      }
      let displayDeviceRegistrationInfo = JSON.stringify(deviceRegistrationInfo)
      commit('SET_DEVICE_REGISTRATION', displayDeviceRegistrationInfo)
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    }
  },
  async reset({ commit, dispatch }) {
    await dispatch('transitionStatus', 'initializing')
    commit('CLEAR_MFA')
    commit('CLEAR_ERROR')
  },
  async reload({ dispatch }) {
    await dispatch('reset')
    await dispatch('initialize')
  },

  /**
   *  HARDWARE MFA DEVICE ACTIONS
   */

  /**
   * registerWebAuthnDevice sends the initiate WebAuthn challenge request to the API,
   * builds the object representing a WebAuthn user credential, and sends the data to
   * be registered to the API.
   */
  async registerWebAuthnDevice({ commit, dispatch, rootState }, deviceName) {
    commit('SET_WEBAUTHN_ERROR', '')
    if (deviceName === '') {
      commit(
        'SET_WEBAUTHN_ERROR',
        handleMFAError(
          new Error('You must enter authenticator name.'),
          'You must enter authenticator name.'
        )
      )
      return false
    }
    try {
      const identity = rootState.identity
      const challengeData = await identity.initiateWebAuthnChallenge()

      const registrationData = await navigator.credentials.create({
        publicKey: challengeData.toPublicKeyCredentialCreationOptions(),
      })

      const response = await identity.registerWebAuthnDevice(
        registrationData,
        deviceName,
        challengeData.tabId
      )

      // Response returns object of MFA Devices registered to the Identity.
      commit('SET_MFA_DEVICES', response)
      return true
    } catch (e) {
      commit(
        'SET_WEBAUTHN_ERROR',
        handleMFAError(
          e,
          'Something went wrong. Unable to register hardware device.'
        )
      )
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      }
      return false
    }
  },
  /**
   * fetchMFADevices sends the fetch request to the List Identities MFA Credentials API,
   * and initializes the status of the WebAuthn component.
   */
  async fetchMFADevices({ commit, dispatch, rootState }) {
    commit('SET_WEBAUTHN_ERROR', '')
    try {
      const identity = rootState.identity
      const mfaDevices = await identity.searchIdentityMFADeviceCredentials(
        rootState.realmName
      )

      // Update state with MFA devices. Devices come back as list from list mfa endpoint
      commit('SET_MFA_DEVICES', mfaDevices[0])

      // This current initializes only the WebAuthn component. In the future, once TOTP is migrated,
      // we may also consider initializing TOTP.
      commit('SET_WEBAUTHN_STATUS', webauthnStatuses.INITIALIZED)
    } catch (e) {
      commit(
        'SET_WEBAUTHN_ERROR',
        handleMFAError(e, 'Something went wrong. Unable to fetch device.')
      )
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      }
    }
  },
  /**
   * removeMFADevice sends a delete request to the Delete MFA Information API,
   * and removes the device if it is registered to the Identity making the request
   */
  async removeMFADevice({ commit, dispatch, rootState }, mfaID) {
    commit('SET_WEBAUTHN_ERROR', '')
    try {
      const identity = rootState.identity
      await identity.removeMFADevice(mfaID) // Returns `true` boolean if successful, otherwise throws and error

      // When successfully removed, get MFA devices to update state.
      // Set state to INITIALIZING. `fetchMFADevices` action will set to INITIALIZED after device state is updated.
      commit('SET_WEBAUTHN_STATUS', webauthnStatuses.INITIALIZING)
      dispatch('fetchMFADevices')
    } catch (e) {
      commit(
        'SET_WEBAUTHN_ERROR',
        handleMFAError(e, 'Something went wrong. Unable to fetch device.')
      )
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      }
    }
  },
  async resetWebauthn({ commit }) {
    commit('CLEAR_WEBAUTHN')
  },
  async showMfaAlert({ commit }, { title, message }) {
    commit('SET_MFA_ALERT', { title, message })
  },
  async closeMfaAlert({ commit }) {
    commit('CLEAR_MFA_ALERT')
  },

  async stepupTotp({ commit, dispatch, rootState, rootGetters }, data) {
    try {
      commit('CLEAR_MFA_ERROR')
      const identity = rootState.identity
      let response = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/mfa/step-up/totp`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )
      response = await identityFetchStatus(response)
      return await response.json()
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = 'Invalid Request! Please try again.'
        commit('SET_MFA_ERROR', { error, status: 'error' })
      }
    }
  },

  async stepupWebauthn({ commit, dispatch, rootState, rootGetters }, data) {
    try {
      commit('CLEAR_MFA_ERROR')
      const identity = rootState.identity
      let response = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/mfa/step-up/webauthn`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )
      response = await identityFetchStatus(response)
      return await response.json()
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = 'Invalid Request! Please try again.'
        commit('SET_MFA_ERROR', { error, status: 'error' })
      }
    }
  },

  async initiateWebauthn({ commit, dispatch, rootState, rootGetters }) {
    try {
      commit('CLEAR_MFA_ERROR')
      const identity = rootState.identity
      let response = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/mfa/step-up/webauthn/initiate`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      response = await identityFetchStatus(response)
      return await response.json()
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = 'Invalid Request! Please try again.'
        commit('SET_MFA_ERROR', { error, status: 'error' })
      }
    }
  },

  async removeCredential(
    { commit, dispatch, rootState, rootGetters },
    payload
  ) {
    const { id, data } = payload
    const params = new URLSearchParams(data).toString()
    try {
      commit('CLEAR_MFA_ERROR')
      const identity = rootState.identity
      const response = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/mfa/credential/${id}?${params}`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
          },
          params: data,
        }
      )
      await identityFetchStatus(response)
      dispatch('fetchMFADevices')
      commit('SET_MFA_ALERT', {
        title: 'Successfully Removed!',
        message: 'The MFA device has been successfully removed',
      })
      return true
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = 'Unable to remove! Please try again.'
        commit('SET_MFA_ERROR', { error, status: 'error' })
      }
    }
  },
}

export default {
  // namespace this modules actions, mutations, and getters under 'mfa' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
