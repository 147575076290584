import tozny from '../../api/tozny'
/**
 * nav implements state for nav menu
 * @module nav
 */

const state = {
  tozIDMpcEnabled: false,
  tozIDSecretsEnabled: false, // If this is true the secret icon will show up, if false it will not show up
  tozIDDisabledAccountTab: false, // If this is true the Account App tab will not be shown
  tozIDDisableFederatedUserAccess: false, // If this is true federated users will not be able to access id portal
}

/** synchronous mutations of nav state */
const mutations = {
  TOGGLE_SECRETS_ENABLED(state) {
    state.tozIDSecretsEnabled = !state.tozIDSecretsEnabled
  },
  SET_MPC_ENABLED(state, enabled) {
    state.tozIDMpcEnabled = enabled
  },
  SET_SECRETS_ENABLED(state, enabled) {
    state.tozIDSecretsEnabled = enabled
  },
  SET_DISABLE_ACCOUNT_TAB(state, disabled) {
    state.tozIDDisabledAccountTab = disabled
  },
  SET_DISABLE_FEDERATED_USER_ACCESS(state, disabled) {
    state.tozIDDisableFederatedUserAccess = disabled
  },
  CLEAR(state) {
    state.tozIDMpcEnabled = false
    state.tozIDSecretsEnabled = false
    state.tozIDDisableFederatedUserAccess = false
  },
}

/** synchronous actions */
const actions = {
  async setupTozIDUsingRealmSetting({ commit, rootState }) {
    // get endpoint to grab realm settings
    const identity = rootState.identity
    let realmPrivateInfo = await tozny.realmPrivateInfo(identity)
    commit('SET_MPC_ENABLED', realmPrivateInfo.mpc_enabled)
    commit('SET_SECRETS_ENABLED', realmPrivateInfo.secrets_enabled)
    commit(
      'SET_DISABLE_ACCOUNT_TAB',
      realmPrivateInfo.disable_account_tab_for_identities
    )
    commit(
      'SET_DISABLE_FEDERATED_USER_ACCESS',
      realmPrivateInfo.disable_federated_user_access_to_id_portal
    )
  },
  async reset({ commit }) {
    commit('CLEAR')
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
