/**
 * State associated with active applications.
 */

/**
 * state and actions surrounding identity applications
 * @module applications
 */

import { isUnauthorized, rootIdentity, checkStatus } from '../../utils/utils'

/** getAppName is used to populate the `name` field of the application if it is not defined. */
function getAppName(app) {
  return app.name || app.id
}

/** initial session state */
const state = {
  /** the account's current billing status */
  status: 'initializing',
  applications: [],
  errorMessage: '',
}

/** cache-able getters for the current applications for the active identity */
const getters = {
  hasError: (state) => {
    return !!state.errorMessage
  },
  noApplications: (state) => {
    return state.status === 'idle' && state.applications.length === 0
  },
}

/** synchronous mutations of tokens state */
const mutations = {
  SET_STATUS(state, next) {
    state.status = next
    state.errorMessage = ''
  },
  SET_APPLICATIONS(state, { applications }) {
    state.applications = applications
  },
  SET_ERROR(state, { error, status }) {
    state.errorMessage = error
    state.status = status
  },
  CLEAR_APPLICATIONS(state) {
    state.applications = []
  },
  CLEAR_ERROR(state) {
    state.errorMessage = ''
  },
}

const statuses = ['initializing', 'loading', 'idle', 'error']

/**
 * Callable state transitions that facilitate async actions and state transitions
 */
const actions = {
  async transitionStatus({ commit }, status) {
    if (statuses.includes(status)) {
      commit('SET_STATUS', status)
    } else {
      commit('SET_ERROR', { error: 'Error: Unknown state', status: 'error' })
    }
  },
  /**
   * Syncs the state machine with the application state, fetching as necessary.
   * @param {context} param0 The vuex state context
   */
  async initialize({ dispatch, state }) {
    // Only act on initializing state
    if (state.status !== 'initializing') {
      return
    }
    // Determine if initial fetch is necessary
    if (!state.applications.length) {
      await dispatch('transitionStatus', 'loading')
      await dispatch('loadApplications')
    } else {
      await dispatch('transitionStatus', 'idle')
    }
  },
  async loadApplications({ commit, dispatch, rootState, rootGetters }) {
    try {
      const identity = rootIdentity(rootState)
      const request = await identity.fetch(
        `${rootGetters['apiUrlRoot']}/integrations`,
        {
          headers: {
            Accept: 'application/json',
          },
        }
      )
      checkStatus(request)
      const applications = await request.json()
      // name is not a required field, so ensure it is populated
      applications.forEach((app) => {
        app.name = getAppName(app)
      })
      commit('SET_APPLICATIONS', {
        // sort the applications alphabetically.
        applications: applications.sort((a, b) => a.name.localeCompare(b.name)),
      })
      await dispatch('transitionStatus', 'idle')
    } catch (e) {
      if (isUnauthorized(e)) {
        dispatch('forceLogout', null, { root: true })
      } else {
        const error = e.message
        commit('SET_ERROR', { error, status: 'error' })
      }
    }
  },
  async reset({ commit, dispatch }) {
    await dispatch('transitionStatus', 'initializing')
    commit('CLEAR_APPLICATIONS')
    commit('CLEAR_ERROR')
  },
}

export default {
  // namespace this modules actions, mutations, and getters under 'applications' namespace
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
