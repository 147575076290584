import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    // this.$store.commit('INITIALIZE_POTENTIAL_PREFERRED_IDP')
    // this.$store.commit('INITIALIZE_PREFERRED_IDP')
  },
}).$mount('#app')
