<template>
  <div
    class="bg-content-bg shadow-sm mb-10"
    :class="{ 'border-2 border-tozny': highlight }"
  >
    <div
      class="h-14 border-b-2 border-gray-light dark:border-main-bg flex justify-between items-center px-4"
      v-if="titleBar"
    >
      <h3 class="font-semibold text-sm" v-if="title">{{ this.title }}</h3>
      <slot name="extras" />
    </div>
    <slot v-if="!loading" />
    <div v-else class="w-full flex items-center justify-center py-4">
      <ToznyLoader class="text-tozny w-24 h-24" />
    </div>
  </div>
</template>

<script>
import ToznyLoader from '@/Common/ToznyLoader'
export default {
  name: 'ContentCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    showTitleBar: Boolean,
    highlight: Boolean,
    loading: Boolean,
  },
  components: { ToznyLoader },
  computed: {
    titleBar() {
      return this.title || this.showTitleBar
    },
    uid() {
      return this._uid
    },
  },
}
</script>
