<template>
  <AuthenticationLayout>
    <ContentCard
      class="border-gray-light dark:border-main-bg border login-form"
    >
      <div id="login-header">
        <div id="pre-login-header"></div>
        <div
          class="flex items-center justify-center border-gray-light border-b dark:border-main-bg p-2 sm:p-4 login-form-header"
        >
          <img
            class="w-32 sm:w-40"
            alt="Tozny logo"
            src="/assets/main-logo.svg"
          />
        </div>
        <div id="post-login-header"></div>
      </div>
      <div id="login-main">
        <div id="pre-login-main"></div>
        <slot />
        <div id="post-login-main"></div>
      </div>
      <div
        class="flex justify-center items-center m-2 text-tiny text-gray-dark dark:text-gray-light"
      >
        Powered by
        <span class="sr-only">Tozny</span>
        <svg class="inline h-5 w-16 ml-1">
          <use href="#tozny-logo" />
        </svg>
      </div>
    </ContentCard>
  </AuthenticationLayout>
</template>

<script>
import AuthenticationLayout from './AuthenticationLayout'
import ContentCard from '@/Common/ContentCard'
export default {
  name: 'AuthenticationForm',
  components: {
    AuthenticationLayout,
    ContentCard,
  },
}
</script>
