import errors from '@toznysecure/sdk/types/errors'

export function parseFragment(fragment) {
  fragment = fragment.replace(/#/, '')
  let parsedParams = {}
  let params = fragment.split('&')
  params.forEach((param) => {
    const parts = param.split('=')
    parsedParams[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1])
  })
  return parsedParams
}

export function identityCacheKey(realmName) {
  return `${realmName}:identity`
}

export function setCache(cacheKey, value) {
  try {
    localStorage.setItem(cacheKey, JSON.stringify(value))
  } catch (_) {
    console.log('Unable to cache')
  }
}

export function loadCache(cacheKey) {
  try {
    const cacheJSON = localStorage.getItem(cacheKey)
    if (cacheJSON == '') {
      return null
    }
    return JSON.parse(cacheJSON)
  } catch (_) {
    return null
  }
}

export function clearCache(cacheKey) {
  try {
    localStorage.removeItem(cacheKey)
  } catch (_) {
    console.log('Unable to clear cache')
  }
}

export function validatePassword(password) {
  if (password.length < 10) {
    return 'Password must be at least 10 characters.'
  }
  return ''
}

export const missingIdentityError = new Error('Identity is not set')

export function invalidCredentialsError(message, response) {
  return new errors.identity.InvalidCredentials(response, message)
}

export function identityFetchStatus(response) {
  if (response.ok) {
    return Promise.resolve(response)
  }
  throw new errors.general.RequestError(response, response.statusText)
}

export function isUnauthorized(error) {
  return (
    error === missingIdentityError ||
    error instanceof errors.identity.InvalidCredentials ||
    (error instanceof errors.general.RequestError &&
      error.response.status === 401)
  )
}

export function rootIdentity(rootState) {
  const identity = rootState.identity
  if (typeof identity !== 'object') {
    throw missingIdentityError
  }
  return identity
}

export function checkStatus(response, message) {
  if (response.status === 401) {
    throw invalidCredentialsError(
      'Account request was not authorized',
      response
    )
  } else if (!response.ok) {
    const errorMessage = message || response.statusText
    throw new Error(errorMessage)
  }
}

export function handleMFAError(e, defaultMessage) {
  // eslint-disable-next-line no-console
  console.error(e)

  switch (e.name) {
    // `NotAllowedError` is the browser's way of telling the application that either the user or browser has
    // denied the application permissions. With regards to MFA registration errors, `NotAllowedError` is thrown
    // in Chrome when the prompt to choose either a security device or "this device" (e.g. Apple Touch ID) is aborted.
    // In Safari, `NotAllowedError` is thrown when attempting to request a user authenticate themselves without the user
    // having authorized Safari to request hardware security keys. Hence the mildly ambiguous error message below.
    case 'NotAllowedError':
      return 'Security key action was canceled or failed to initiate. Please try again.'
    case 'AbortError':
      return 'Security key action aborted.'
    case 'RequestError':
      if (!e.response) {
        break
      }
      if (e.response.status === 404) {
        return 'Device already removed.'
      }
      if (e.response.status === 501) {
        return 'Unauthorized to perform security key request.'
      }
      if (e.response.status === 500) {
        return 'Security key request failed due to internal server error.'
      }
      if (e.response.status === 503) {
        return 'Service is unavailable. Cannot update security key.'
      }
      break
  }
  return defaultMessage || 'An unexpected error occurred. Please try again.'
}
